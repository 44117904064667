import React, { Component } from 'react';

class Module extends Component {
    render() {
        return (
            <img alt="Test" class="Pixelate" src="/components/Pixelate/images/1.jpg" />
        );
    }
}
export default Module;
