import React, { Component } from 'react';

class Module extends Component {
    
    render() {
        
        return (
            <div class="Hover">
                
            </div>
        );
    }
}
export default Module;
