import React, { Component } from 'react';

class Module extends Component {
    
    render() {

        return (
            <div className="Select">
                <select>
                    <option>This is a select</option>
                </select>
            </div>
        );
    }
}
export default Module;
