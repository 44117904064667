import React, { Component } from 'react';

class Module extends Component {
    
    render() {
        
        return (
            <div className="Buttons">
                <button type="button" class="success">Success</button>

<a href="#button" class="Button">Link</a>
<button href="#button" >Button</button>

<button type="button" class="next">Next</button>
            </div>
        );
    }
}
export default Module;
