import React, { Component } from 'react';

class Module extends Component {
    
    render() {
        return (
            <div class="Shadows">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
        );
    }
}

export default Module;
