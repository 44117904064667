import React, { Component } from 'react';

class Module extends Component {
    
    render() {
        return (
            <div class="Layout">
                <div>Header</div>
                <div>Menu</div>
                <div>Main</div>  
                <div>Footer</div>
            </div>
        );
    }
}

export default Module;
